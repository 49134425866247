import React, {useContext} from 'react';
import {AppContext} from "../../context/AppContext";
import BingoCard from "./components/BingoCard/BingoCard";
import {Room} from "../../types/Room";
import styles from './BingoBlock.module.scss'

interface BingoBlockProps {
    heading: string;
}

const BingoBlock = ({heading}: BingoBlockProps) => {
    const {rooms} = useContext(AppContext);

    return (
        <div className={styles.blockContainer}>
            <div className={styles.titleContainer}>
            <h2 className={styles.title}>{heading}</h2>
            </div>
            <div className={styles.content}>
                {rooms.map((room: Room) => (
                    <BingoCard key={room.id} room={room}/>
                ))}
            </div>
        </div>
    );
};

export default BingoBlock;
