import {MouseEventHandler, ReactNode} from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

interface Props {
    children?: ReactNode;
    className?: string;
    onClick?: MouseEventHandler;
    isSecondary?: boolean;
    isTertiary?: boolean;
    isDisabled?: boolean;
    isActive?: boolean;
    isPrimaryBig?: boolean;
}

function Button({
                    children,
                    className,
                    onClick,
                    isPrimaryBig,
                    isSecondary,
                    isTertiary,
                    isDisabled,
                    isActive,
                }: Props): JSX.Element {
    const isPrimary: boolean = !isSecondary && !isPrimaryBig && !isTertiary;

    return (
        <button
            type="button"
            disabled={!!isDisabled}
            className={clsx(className, styles.wrapper, {
                [styles.primary]: isPrimary,
                [styles.primaryBig]: isPrimaryBig,
                [styles.secondary]: isSecondary,
                [styles.tertiary]: isTertiary,
                [styles.active]: isActive,
                [styles.disabled]: isDisabled,
            })}
            onClick={onClick}
        >
            {children && <div className={styles.childrenWrapper}>{children}</div>}
        </button>
    );
}

export default Button;
