import React from 'react';
import styles from './TextBlock.module.scss'
import clsx from "clsx";

interface TextBlockProps {
    className?: string;
    heading?: string;
    paragraphs: string[];
    margin?: string;
    background?: string;
}

const TextBlock = ({className, heading, paragraphs, margin, background}: TextBlockProps) => {
    return (
        <div className={clsx(styles.textBlock, {
            [styles.specialStyles]: className,
        })}
             style={{margin, background}}>
            {heading && <h1>{heading}</h1>}
            {paragraphs.map((paragraph, index) => (
                <p style={{margin}} key={index}>{paragraph}</p>
            ))}
        </div>
    );
};

export default TextBlock;
