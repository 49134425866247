import React from 'react';
import DepositComponent from "../../components/Deposit/Deposit";
import Menu from "../../components/Menu/Menu";

const Deposit = () => {
    return <>
        <Menu/>
        <DepositComponent/>
    </>;
};

export default Deposit;
