"use client";
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {ReactNode, useEffect, useState} from 'react';
import {useMutation, useQuery} from "react-query";
import apiClient from "../utils/apiClient";
import axios from "axios";
import qs from "qs";
import {useNavigate} from "react-router-dom";

interface TokenData {
    access_token: string;
    refresh_token: string;
}

interface UserBalance {
    totalValue: number;
}

interface UserData {
    username: string;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    nickname: string;
    userId: number;
    avatarId: number;
    streetAddress: string;
    zip: string;
    city: string;
    state: string;
    phoneNumber: string;
}

interface AppContextProps {
    tokenData: TokenData | null;
    setTokenData: React.Dispatch<React.SetStateAction<TokenData | null>>;
    userData: UserData | null;
    setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
    userBalance: UserBalance | null;
    setUserBalance: React.Dispatch<React.SetStateAction<UserBalance | any>>;
    refetchCustomerData: () => void;
    refetchCustomerBalance: () => void;
    currencyFormat: string;
    setCurrencyFormat: (val: string) => void;
    currencyLocale: string;
    setCurrencyLocale: (val: string) => void;
    login: (username: string, password: string) => Promise<void>;
    loading: boolean;
    logoutUser: (isUnvalidUser: boolean) => void;
    rooms: any[];
    setRooms: (val: []) => void;
}

interface AppProviderProps {
    children: ReactNode;
    value: Partial<AppContextProps>;
}

const initialAppContextProps: AppContextProps = {
    tokenData: null,
    setTokenData: () => {
    },
    userData: null,
    setUserData: () => {
    },
    userBalance: null,
    setUserBalance: () => {
    },
    refetchCustomerData: () => {
    },
    refetchCustomerBalance: () => {
    },
    currencyFormat: '',
    setCurrencyFormat: () => {
    },
    currencyLocale: '',
    setCurrencyLocale: () => {
    },
    loading: true,
    login: async () => {
    },
    logoutUser: () => {
    },
    rooms: [],
    setRooms: () => {
    },
};

const AppContext = React.createContext<AppContextProps>(initialAppContextProps);

function AppProvider({children, value}: AppProviderProps) {
    const navigate = useNavigate();
    const [tokenData, setTokenData] = useState<TokenData | null>(null);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [userBalance, setUserBalance] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currencyFormat, setCurrencyFormat] = useState('');
    const [currencyLocale, setCurrencyLocale] = useState('');
    const [rooms, setRooms] = useState([]);

    const loginRequest = async (userobj: { username: string; password: string }) => {
        try {
            const requestObj = qs.stringify({
                grant_type: 'password',
                username: userobj.username,
                password: userobj.password,
                client_secret: 'secret_for_randomstate_customer',
                usertype: 'Customer',
                client_id: 'randomstate_customer',
                scope: 'randomstate_customer offline_access',
            });
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            };
            const url = `${apiClient.baseURL}connect/token`;
            return await axios.post(url, requestObj, config);
        } catch (error: any) {
            throw error.response || new Error('Login request failed');
        }
    };

    const loginQuery = useMutation(loginRequest, {
        onSuccess: async (response) => {
            if (response && response.data) {
                apiClient.setClientToken(response.data.access_token);
                setTokenData(response.data);
                localStorage.setItem('tokenData', JSON.stringify(response.data));
            }
        },
    });

    const login = async (username: string, password: string) => {
        await loginQuery.mutateAsync({ username, password });
    };

    const logout = useMutation(() => apiClient.get('logout', {}), {
        onSuccess: () => {
            apiClient.setClientToken('');
        },
        onError: (error) => {
            apiClient.setClientToken('');
            console.log('error', error);
        },
    });

    const logoutUser = (isUnvalidUser: boolean) => {
        if (isUnvalidUser) {
            apiClient.setClientToken('');
        } else {
            logout.mutate();
        }
        localStorage.removeItem('tokenData');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('loggedInTime');
        sessionStorage.removeItem('userHasPlayed');
        localStorage.removeItem('userPaymentData');
        setUserData(null);
        setTokenData(null);
        setUserBalance(null);
    };

    const fetchUserBalance = async () =>
        apiClient.get('api/CustomerTransaction/CombinedBalance', {});

    const balance = useQuery('balance', fetchUserBalance, {
        refetchInterval: 20000,
        enabled: !!tokenData?.access_token,
        onSuccess: (response: any) => {
            setUserBalance(response);
        },

        onError: (error: any) => {
            console.error('error', error);
        },
        refetchOnWindowFocus: false,
    });

    const refetchCustomerBalance = () => {
        balance.refetch();
    };

    const bingoRooms = useQuery('bingoRooms', () => apiClient.get('api/Bingo/rooms', {}), {
        refetchInterval: 25000,
        onSuccess: (response: any) => {
            setRooms(response);
        },
        onError: (error: any) => {
            console.error('error', error);
        },
        refetchOnWindowFocus: false,
    });

    const customer = useQuery('user', () => {
        setLoading(true);
        return apiClient.get('api/Customer', {});
    }, {
        enabled: !!tokenData?.access_token,
        refetchOnWindowFocus: false,
        onSuccess: (response: any) => {
            setUserData(response);
            setLoading(false);
        },
        onError: (error) => {
            console.error('error', error);
            setLoading(false);
        },
    });

    const refetchCustomerData = () => {
        customer.refetch();
    };

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem('tokenData') as string);
        if (localData) {
            apiClient.setClientToken(localData.access_token);
            setTokenData(localData);
        }
        apiClient.renewedTokenSignal.add((newTokenData) => {
            setTokenData(newTokenData);
        });
        apiClient.userNotVerified.add((status) => {
            logoutUser(true);
            navigate('/');
            if (status === '400') {
                console.log('User not verified');
            }
            if (status === '401') {
                console.log('User not verified');
            }
        });
        return () => {
            apiClient.renewedTokenSignal.detachAll();
            apiClient.userNotVerified.detachAll();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                ...value,
                tokenData,
                setTokenData,
                login,
                loading,
                logoutUser,
                rooms,
                setRooms,
                userData,
                setUserData,
                userBalance,
                setUserBalance,
                refetchCustomerData,
                refetchCustomerBalance,
                currencyFormat,
                setCurrencyFormat,
                currencyLocale,
                setCurrencyLocale,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

AppProvider.defaultProps = {
    value: {
        userData: null,
        tokenData: null,
    },
};

export {AppContext, AppProvider};
