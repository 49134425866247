import axios, { AxiosRequestConfig } from 'axios';
import {MiniSignal} from 'mini-signals';
import qs from 'qs';

const baseURL = process.env.REACT_APP_BASE_URL;
const renewedTokenSignal = new MiniSignal();
const userNotVerified = new MiniSignal();
let isFetchingNewToken = false;

const client = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

/**
 * Axios response interceptor.
 * Checks if we get an 401 (Unauthorized) error.
 * Then requests a new token using the refresh_token.
 */
// response interceptor to refresh token on receiving token expired error
client.interceptors.response.use(
    (response) => response.data || response,

    (error) => {
        const originalRequest = error.config;
        const localData = JSON.parse(<string>localStorage.getItem('tokenData'));
        let refreshToken;
        if (localData) {
            refreshToken = localData.refresh_token;
        }
        if (
            !isFetchingNewToken &&
            refreshToken &&
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            isFetchingNewToken = true;
            originalRequest._retry = true;
            const formData = qs.stringify({
                client_secret: 'secret_for_randomstate_customer',
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
                client_id: 'randomstate_customer',
            });
            return axios
                .post(`${baseURL}connect/token`, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        client.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
                        originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
                        localStorage.setItem('tokenData', JSON.stringify(res.data));
                        renewedTokenSignal.dispatch(res.data);
                        isFetchingNewToken = false;
                        return client.request(originalRequest);
                    }
                })
                .catch((e) => {
                    isFetchingNewToken = false;
                    if (e.response) {
                        if (e.response.status === 400) {
                            userNotVerified.dispatch('400');
                        }
                    }
                    if (error.message === 'Request failed with status code 401') {
                        userNotVerified.dispatch('401');
                    }
                });
        }
        return Promise.reject(error.response || error);
    }
);

const setClientToken = (token: any) => {
    return new Promise((resolve, reject) => {
        try {
            client.defaults.headers.common.Authorization = `Bearer ${token}`;
            resolve(token);
        } catch (error) {
            reject(error);
        }
    });
};

const apiClient = {
    baseURL,
    setClientToken,
    get: (url: string, options: AxiosRequestConfig<any> | undefined) => client.get(baseURL + url, options),
    post: (url: string, payload: any) => client.post(baseURL + url, payload),
    put: (url: string, payload: any) => client.put(baseURL + url, payload),
    patch: (url: string, payload: any) => client.patch(baseURL + url, payload),
    delete: (url: string) => client.delete(baseURL + url),
    renewedTokenSignal,
    userNotVerified,
};

export default apiClient;
