import React, {useContext, useState} from 'react';
import styles from "./Deposit.module.scss";
import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../context/AppContext";
import RadioButton from "../../elements/Radio/Radio";
import freedompay from '../../assets/images/payment/freedompay.png'
import TextBlock from "../../elements/TextBlock/TextBlock";

const paymentOptions = [
    {name: 'FreedomPay', image: freedompay},
    // {name: 'AmericanExpress'},
    // {name: 'Visa'},
    // {name: 'MasterCard'},
];

const DepositComponent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {refetchCustomerBalance} = useContext(AppContext);
    const [amount, setAmount] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [generalError, setGeneralError] = useState('');
    const [inputErrors, setInputErrors] = useState({
        amount: '',
    });

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        setAmount(value);

        handleValidation([name]);
    };

    const handleOptionChange = (option: any) => {
        setSelectedOption(option);
        setAmount(0)
        inputErrors.amount = '';
    };

    const handleValidation = (inputFieldValue: any) => {
        const errors = {...inputErrors};
        let isValid = true;

        if (inputFieldValue === 'amount') {
            if (amount <= 0) {
                errors.amount = t('deposit.amountValidation');
                isValid = false;
            } else {
                errors.amount = '';
            }
        }

        setInputErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (handleValidation(['email', 'password'])) {
            try {
                console.log('amount', amount);
                // await to deposit smth
                navigate('/');
                refetchCustomerBalance();
            } catch (error: any) {
                console.error(error);

                if (error && error.status === 400) {
                    setGeneralError(t('deposit.error'));
                    setInputErrors({
                        amount: '',
                    });
                    setAmount(0);
                }
            }
        }
    };

    return (
        <div className={styles.formContainer}>
            <TextBlock
                heading={t('deposit.heading')}
                paragraphs={[t('deposit.subheading')]}
                margin={'30px 0 30px 0'}
            />
            <form className={styles.form} key={'signIn'}>
                <label className={styles.label}>{t('deposit.selectMethod')}</label>
                <div className={styles.payment}>
                    {paymentOptions &&
                        paymentOptions.map(({name, image}) => (
                            <RadioButton
                                key={name}
                                isChecked={selectedOption === name}
                                name={name}
                                label={image}
                                onChange={() => handleOptionChange(name)}
                                isDisabled={false}
                            />
                        ))}
                </div>
                <div className={styles.depositContainer}>
                    <Input
                        type="number"
                        isMoneyAmount={true}
                        name={t('deposit.amount')}
                        initialValue={''}
                        value={amount}
                        placeholder={t('deposit.amount')}
                        label={t('deposit.amountLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                            if (!inputErrors.amount) {
                                handleValidation([e.target.name]);
                            }
                        }}
                        inputMode="decimal"
                        error={inputErrors.amount}
                        success={inputErrors.amount === '' && amount > 0}
                    />
                    <Button
                        className={styles.submitButton}
                        isDisabled={inputErrors.amount !== '' || inputErrors.amount !== ''}
                        onClick={handleSubmit}
                    >
                        {t('deposit.depositButton')}
                    </Button>
                </div>
                <p className={styles.comment}>{t('deposit.comment')}</p>
                {generalError && <p className={styles.generalError}>{generalError}</p>}
            </form>
        </div>
    );
};

export default DepositComponent;
