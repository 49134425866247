import {useContext} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styles from './Header.module.scss';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Button from "../../elements/Button/Button";
import ProfileMenu from "./components/ProfileMenu/ProfileMenu";

function Header({isLoginHidden}: { isLoginHidden: boolean }) {
    const navigate = useNavigate();
    const location = useLocation();
    const {tokenData} = useContext(AppContext);
    const {t} = useTranslation();

    const hideProfileMenuOnMobilePaths = ['/deposit', '/withdraw', '/profile', '/game-history'];
    const shouldHideProfileMenuOnMobile = hideProfileMenuOnMobilePaths.includes(location.pathname);

    return (
        <div className={clsx(styles.wrapper)} id='header'>
            <a href={'/'} className={styles.logoContainer}>
                <div className={styles.logo}></div>
            </a>
            {!tokenData && !isLoginHidden && (
                <div className={styles.buttonContainer}>
                    <Button
                        className={styles.loginButton}
                        onClick={() => {
                            navigate({
                                pathname: '/login',
                            });
                        }}
                    >
                        {t('header.signIn')}
                    </Button>
                </div>
            )}
            {tokenData && (
                <div className={clsx(styles.menuContainer, {
                    [styles.hideOnMobile]: shouldHideProfileMenuOnMobile
                })}>
                    <ProfileMenu/>
                </div>
            )
            }
        </div>
    );
}

export default Header;
