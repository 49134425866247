import avatar1 from '../assets/avatars/user-picture-1.svg'
import avatar2 from '../assets/avatars/user-picture-2.svg'
import avatar3 from '../assets/avatars/user-picture-3.svg'
import avatar4 from '../assets/avatars/user-picture-4.svg'
import avatar5 from '../assets/avatars/user-picture-5.svg'
import avatar6 from '../assets/avatars/user-picture-6.svg'
import avatar7 from '../assets/avatars/user-picture-7.svg'
import avatar8 from '../assets/avatars/user-picture-8.svg'
import avatar9 from '../assets/avatars/user-picture-9.svg'
import avatar10 from '../assets/avatars/user-picture-10.svg'
import avatar11 from '../assets/avatars/user-picture-11.svg'
import avatar12 from '../assets/avatars/user-picture-12.svg'
import avatar13 from '../assets/avatars/user-picture-13.svg'
import avatar14 from '../assets/avatars/user-picture-14.svg'
import avatar15 from '../assets/avatars/user-picture-15.svg'
import avatar16 from '../assets/avatars/user-picture-16.svg'
import avatar17 from '../assets/avatars/user-picture-17.svg'
import avatar18 from '../assets/avatars/user-picture-18.svg'
import avatar19 from '../assets/avatars/user-picture-19.svg'
import avatar20 from '../assets/avatars/user-picture-20.svg'
import avatar21 from '../assets/avatars/user-picture-21.svg'
import avatar22 from '../assets/avatars/user-picture-22.svg'
import avatar23 from '../assets/avatars/user-picture-23.svg'
import defaultImage from '../assets/avatars/user-picture-24.svg'

const userImages = [
    defaultImage,
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18,
    avatar19,
    avatar20,
    avatar21,
    avatar22,
    avatar23,
];

const getUserAvatar = (index = 0) => userImages[index];

export { userImages, getUserAvatar };
