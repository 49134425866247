import React, {useState} from 'react';
import styles from './GameHistory.module.scss'
import TextBlock from "../../elements/TextBlock/TextBlock";
import {useTranslation} from "react-i18next";
import apiClient from "../../utils/apiClient";
import {useQuery} from "react-query";
import HistoryRow from './components/HistoryRow';
import Button from "../../elements/Button/Button";
import Spinner from "../../elements/Spinner/Spinner";

const pageSize = 10;

type HistoryRowProps = {
    createdDate: string;
    gameBaseName: string;
    wonGoodsValue: number;
    wonMoneyValue: number;
    correlationId: string;
    moneyValue: number;
    goodsValue: number;
    bonusValue: number;
    isBingoGame: boolean;
}

const GameHistoryComponent = () => {
    const {t} = useTranslation();
    const [page, setPage] = useState(1);
    const tableHeaders = [
        t('history.date'),
        t('history.game'),
        t('history.wager'),
        t('history.prize'),
        t('history.wager'),
    ];

    const fetchHistory = ({pageParam = 1}) =>
        apiClient.get(`api/CustomerTransaction/customerpurchases/${pageParam}/${pageSize}`, {});

    const {data, isLoading, isError} = useQuery(
        ['history', page],
        () => fetchHistory({pageParam: page}),
        {
            refetchOnWindowFocus: false,
        }
    );

    const loadMoreData = (increment: number) => {
        setPage(prevPage => prevPage + increment);
    };

    if (isLoading) {
        return <Spinner/>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    if (!data) {
        return null;
    }

    // @ts-ignore
    const filteredData = data.filter(({bingoName}) => bingoName === null);
    console.log(filteredData);

    return (
        <div className={styles.container}>
            <TextBlock
                heading={t('history.heading')}
                paragraphs={[t('history.subheading')]}
                margin={'30px 0 30px 0'}
            />

            <table className={styles.table}>
                <thead className={styles.thead}>
                <tr className={styles.row}>
                    {tableHeaders &&
                        tableHeaders.map((tableHeader) => (
                            <th key={tableHeader} className={styles.tableHeader}>
                                {tableHeader}
                            </th>
                        ))}
                </tr>
                </thead>
                <tbody>
                {filteredData.length > 0 ? (
                    filteredData.map(({
                                          createdDate, gameBaseName, wonGoodsValue,
                                          wonMoneyValue, correlationId,
                                          moneyValue, goodsValue, bonusValue, isBingoGame,
                                      }: HistoryRowProps) => (
                        <tr key={correlationId} className={styles.row}>
                            <td className={styles.tableData}>
                                <HistoryRow
                                    datePlayed={createdDate}
                                    name={gameBaseName}
                                    wager={moneyValue + goodsValue + bonusValue}
                                    prize={wonGoodsValue + wonMoneyValue}
                                    isBingoGame={isBingoGame}
                                    onClickReplay={() => {
                                        console.log('clicked');
                                    }}
                                />
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={tableHeaders.length} className={styles.noData}>
                            <h2>{t('history.noData')}</h2>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className={styles.paginationContainer}>
                <Button isSecondary onClick={() => loadMoreData(-1)}
                        isDisabled={page <= 1}>{t('history.previous')}</Button>
                <p>{t('history.page', {page})}</p>
                <Button isSecondary onClick={() => loadMoreData(1)}>{t('history.next')}</Button>
            </div>
        </div>
    );
};

export default GameHistoryComponent;
