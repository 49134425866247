import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import styles from './HistoryRow.module.scss'
import Util from "../../../utils/Util";
import {useTranslation} from "react-i18next";

interface HistoryRowProps {
    datePlayed: string;
    name: string;
    wager: number;
    prize: number;
    isBingoGame: boolean;
    onClickReplay: () => void;
}

const HistoryRow = ({ datePlayed, name, wager, prize, isBingoGame, onClickReplay }: HistoryRowProps) => {
    const {t} = useTranslation();
    const [displayDate, setDisplayDate] = useState<string | null>(null);

    useEffect(() => {
        const formattedDate = dayjs(datePlayed).format('MM/DD/YYYY hh:mm a');
        setDisplayDate(formattedDate);
    }, [datePlayed]);

    return (
        <div className={styles.container}>
            <span className={styles.date}>{displayDate}</span>
            <span className={styles.name}>{name}</span>
            <span className={styles.wager}>{Util.formatCurrency(Math.abs(wager))}</span>
            <span className={styles.prize}>{Util.formatCurrency(prize)}</span>
            {isBingoGame ?
                <span className={styles.replay} onClick={onClickReplay}>{t('history.replay')}</span>
                :
                <span className={styles.replay}></span>
            }
        </div>
    );
};

export default HistoryRow;
