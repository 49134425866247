import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import Util from '../../utils/Util';
import styles from './Input.module.scss';

interface InputProps {
    classNameInputWrapper?: string;
    type: string;
    name?: string;
    initialValue?: string | number;
    value: string | number;
    placeholder?: string;
    disabled?: boolean;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    error: string;
    success?: boolean;
    isMoneyAmount?: boolean;
    inputMode: 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
    comment?: string;
}

function Input({
                   classNameInputWrapper,
                   type,
                   name,
                   initialValue,
                   value,
                   placeholder,
                   disabled,
                   label,
                   onChange,
                   onBlur,
                   error,
                   success,
                   isMoneyAmount,
                   inputMode,
                   comment,
               }: InputProps) {
    const [internalValue, setInternalValue] = useState(initialValue);
    const [isFocus, setIsFocus] = useState(false);
    const [formattedCurrency, setFormattedCurrency] = useState<string | null>(null);

    function getType() {
        if (type === 'number' && isMoneyAmount && formattedCurrency) {
            return 'text';
        }
        return type;
    }

    function getValue() {
        if (type === 'number' && isMoneyAmount) {
            if (formattedCurrency) {
                return formattedCurrency;
            }
        }
        return internalValue;
    }

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useEffect(() => {
        if (initialValue) {
            setInternalValue(initialValue);

            if (type === 'number' && isMoneyAmount && !isFocus) {
                setFormattedCurrency(Util.formatCurrency(Number(initialValue)));
            }
        }
    }, [initialValue]);

    return (
        <div className={clsx(classNameInputWrapper, styles.container)}>
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label}
                </label>
            )}
            <div className={clsx(styles.inputContainer, {
                [styles.hasError]: error !== '',
                [styles.hasSuccess]: success,
            })}>
                <input
                    className={clsx(styles.input, {
                        [styles.numberField]: type === 'number',
                        [styles.hasError]: error !== '',
                    })}
                    type={getType()}
                    inputMode={inputMode}
                    id={name}
                    name={name}
                    value={getValue()}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete={
                        type === 'number' || isMoneyAmount || inputMode === 'numeric'
                            ? 'off'
                            : 'on'
                    }
                    onFocus={() => {
                        setIsFocus(true);
                        if (isMoneyAmount) {
                            setFormattedCurrency(null);
                        }
                    }}
                    onBlur={(e) => {
                        setIsFocus(false);
                        onBlur(e);
                        if (isMoneyAmount && Number(e.target.value) > 0) {
                            setFormattedCurrency(Util.formatCurrency(Number(value)));
                        }
                    }}
                    onChange={(e) => {
                        setInternalValue(e.target.value);
                        onChange(e);
                    }}
                />
            </div>
            {comment && <p className={styles.comment}>{comment}</p>}
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
}

export default Input;
