import React from 'react';
import Menu from "../../components/Menu/Menu";
import GameHistoryComponent from "../../components/GameHistory/GameHistory";

const GameHistory = () => {
    return <>
        <Menu/>
        <GameHistoryComponent/>
    </>;
};

export default GameHistory;
