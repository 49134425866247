import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import styles from './ProfileMenu.module.scss';
import Button from "../../../../elements/Button/Button";
import gsap from "gsap";
import Util from "../../../../utils/Util";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import arrow from "../../../../assets/icons/menu-arrow.png";
import {getUserAvatar} from "../../../../utils/getAvatar";

const ProfileMenu = () => {
    const previousBalance = useRef<any>();
    const balance = useRef<any>();
    const {t} = useTranslation();
    const {logoutUser, userBalance, userData} = useContext(AppContext);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [total, setTotal] = useState<string>('');

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownVisible(false);
        }
    };

    const bounceBalance = () => {
        gsap.to(balance.current, {
            scale: 1.3,
            x: -5,
            ease: 'Sine.easeOut',
            duration: 0.4,
        });
        gsap.to(balance.current, {
            scale: 1,
            x: 0,
            delay: 0.1,
            ease: 'Sine.easeOut',
        });
    };

    const animateBalance: () => void = () => {
        gsap.to(previousBalance.current, {
            value: userBalance?.totalValue,
            duration: 0.5,
            ease: 'power2.out',
            onUpdate: () => {
                const totalString: string = Util.formatCurrency(previousBalance.current.value);
                setTotal(totalString);

                bounceBalance();
            },
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (userBalance?.totalValue !== null) {
            setTotal((oldVal: string) => {
                if (oldVal) {
                    const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));

                    previousBalance.current = {
                        value: oldValNumber,
                    };
                    if (userBalance !== null) {
                        if (oldValNumber !== userBalance.totalValue) {
                            animateBalance();
                        }
                    }
                }

                if (userBalance !== null) {
                    return Util.formatCurrency(userBalance.totalValue);
                }
                return '';
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userBalance]);

    return (
        <div className={styles.dropdown} onMouseEnter={handleMouseEnter} ref={dropdownRef}>
            <Button className={clsx(styles.dropbtn, {
                [styles.dropbtnActive]: isDropdownVisible,
            })}>
                <div className={styles.content}>
                    <div className={styles.balanceWrapper}>
                        <img
                            className={styles.avatar}
                            src={getUserAvatar(userData ? userData.avatarId : 0)}
                            alt="avatar"
                        />
                        <div>
                        <div className={styles.nicknameContainer}>
                            <span>{userData?.nickname}</span>
                        </div>
                        <span className={styles.balanceLabel}>
                            {t('header.wallet')}
                        </span>

                        <span ref={balance} className={styles.balance}>
                            {total}
                        </span>
                        </div>
                    </div>
                    <img className={clsx(styles.arrow, {
                        [styles.arrowActive]: isDropdownVisible,
                    })}
                         src={arrow}
                         alt=""
                         style={{width: '16px', height: '16px'}}
                    />
                </div>
            </Button>
            <div className={clsx(styles.dropdown_content, {
                [styles.isVisible]: isDropdownVisible,
            })}>
                <a href="/profile">{t('header.profileMenu.profile')}</a>
                <div className={styles.break}></div>
                <a href="/game-history">{t('header.profileMenu.gameHistory')}</a>
                <div className={styles.break}></div>
                <a href="/deposit">{t('header.profileMenu.deposit')}</a>
                <div className={styles.break}></div>
                <a href="/withdraw">{t('header.profileMenu.withdraw')}</a>
                <div className={styles.break}></div>
                <a href="/" onClick={() => logoutUser(false)}>{t('header.profileMenu.logout')}</a>
            </div>
        </div>
    );
};

export default ProfileMenu;
