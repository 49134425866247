import React from 'react';
import styles from './Menu.module.scss'
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

const menuItems = [
    {
        name: 'My Profile',
        link: '/profile',
    },
    {
        name: 'Game History',
        link: '/game-history',
    },
    {
        name: 'Deposit',
        link: '/deposit',
    },
    {
        name: 'Withdraw',
        link: '/withdraw',
    },
]

const Menu = () => {
    const navigate = useNavigate();
    const activePath = window.location.pathname;

    return (
        <div className={styles.menuContainer}>
            <ul className={styles.menu}>
                {menuItems.map((item, index) => (
                    <li className={clsx(styles.menuItem, {
                        [styles.active]: activePath === item.link
                    })}
                        onClick={() => {
                            navigate(item.link)
                        }}
                        key={index}>
                        {item.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Menu;
