import React from 'react';
import ProfileComponent from "../../components/Profile/Profile";
import Menu from "../../components/Menu/Menu";

const Profile = () => {
    return <>
        <Menu/>
        <ProfileComponent/>
    </>;
};

export default Profile;
