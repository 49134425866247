import { useLocation } from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AppContext} from "../../context/AppContext";

const GameClient = () => {
    const location = useLocation();
    const { tokenData } = useContext(AppContext);
    const { roomId } = location.state || {};
    console.log('GameClient roomId: ', roomId);

    const initializeBingoClient = () => {
        const BingoClient = window.BingoClient;
        window.publicPath = 'https://test.clients.randomstate.se/customers/planetbingo/bingoclient/';
        if (!BingoClient || !BingoClient.init) {
            console.error('BingoClient is not loaded or init method is missing');
            return;
        }

        const token = localStorage.getItem('tokenData');

        const data = {
            elementId: 'appbingo',
            apiURL: process.env.REACT_APP_BASE_URL,
            tokenData: tokenData || JSON.parse(token as string),
            language: 'en',
            region: 'en-us',
            currency: 'USD',
            room: roomId.toString(),
            gameResponsibilityLink: '',
            returnButtonURL: '/',
            bingocardId: null,
            callback_login: () => window.location.href = '/login',
            callback_deposit: () => window.location.href = '/deposit',
            callback_purchase: (obj: any) => console.log(obj),
            callback_game_finished: (obj: any) => console.log(obj),
            callback_jackpot: (BingoGameID: any, BingoGameName: any, JackpotSize: any) => console.log(BingoGameID, BingoGameName, JackpotSize),
            bingo_room_links: [
                { id: 1, url: '/play?room=1' },
                { id: 2, url: '/play?room=2' },
            ],
        };

        console.log('Initializing BingoClient with data: ', data);
        BingoClient.init(data);
    };

    useEffect(() => {
        if (roomId) {
            initializeBingoClient();
        }
    }, [roomId]);

    return (
        <div id="appbingo" style={{width: '100%', maxWidth: '100vw'}}/>
    );
};

export default GameClient;
