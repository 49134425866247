import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import setInnerHeightVar from "./utils/innerHeightVariable";
import Providers from "./components/Providers/Providers";
import './index.scss';

setInnerHeightVar();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
    <Router>
        <Providers>
            <App/>
        </Providers>
    </Router>
    </React.StrictMode>
);
