import React, {useEffect, useState} from 'react';
import {Room} from "../../../../types/Room";
import clsx from "clsx";
import styles from './BingoCard.module.scss';
import Util from "../../../../utils/Util";
import {useTranslation} from "react-i18next";
import player from '../../../../assets/icons/player.png'
import clock from '../../../../assets/icons/clock.png'
import Button from "../../../../elements/Button/Button";
import {useNavigate} from "react-router-dom";

interface BingoCardProps {
    room: Room;
}

const BingoCard = ({room}: BingoCardProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [image, setImage] = useState('');
    const [logo, setLogo] = useState('');
    const [prevImage, setPrevImage] = useState('');
    const [prevLogo, setPrevLogo] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const [logoLoaded, setLogoLoaded] = useState(false);
    const round = room.nextRound || room.currentRound;
    const [timeUntil, setTimeUntil] = useState(round ? Util.getTimeUntil(round.drawStartTime) : 0);

    const onClick = () => {
        console.log('Joining room: ' + room.id);
        navigate('/game-client', { state: { roomId: room.id } });
    }

    useEffect(() => {
        if (!round) return;

        const imageUrl = process.env.REACT_APP_BASE_URL + round.bingoGame.backgroundBig;
        const logoUrl = process.env.REACT_APP_BASE_URL + round.bingoGame.logoBig;

        if (imageUrl !== prevImage) {
            const fetchImage = async () => {
                const response = await fetch(imageUrl, {});
                const imageVal = await response.text();
                setImage(imageVal);
                setPrevImage(imageUrl);
            };
            fetchImage();
        }

        if (logoUrl !== prevLogo) {
            const fetchLogo = async () => {
                const response = await fetch(logoUrl, {});
                const logoVal = await response.text();
                setLogo(logoVal);
                setPrevLogo(logoUrl);
            };
            fetchLogo();
        }
    }, [round, prevImage, prevLogo]);

    useEffect(() => {
        if (!round) return;

        const interval = setInterval(() => {
            setTimeUntil(Util.getTimeUntil(round.drawStartTime));
        }, 1000);

        return () => clearInterval(interval);
    }, [round]);

    return (
        <div className={clsx(styles.wrapper, {})}>
            <div className={styles.container}>
                <div className={styles.nameBadge}>
                    <h5 className={styles.roomName}>{room.name}</h5>
                </div>
                <div className={styles.playersBadge}>
                    <img src={player} alt="" style={{width: '24px', height: '24px'}}/>
                    <div>{room.numberOfActivePlayers}</div>
                </div>
                <div className={styles.priceBadge}>
                    <div>{round ? (round.bingoGame.cardPrice == 0 ? 'Free!' : Util.formatCurrency(round.bingoGame.cardPrice) + '/card') : 'Waiting'}</div>
                </div>
                <div className={styles.timeBadge}>
                <img src={clock} alt="" style={{width: '24px', height: '24px'}}/>
                    <div>{timeUntil}</div>
                </div>
                <img
                    className={clsx(styles.image, {[styles.loaded]: imageLoaded})}
                    src={image}
                    alt=""
                    onLoad={() => setImageLoaded(true)}
                />
                <img
                    className={clsx(styles.image, {[styles.loaded]: logoLoaded})}
                    style={{position: 'absolute', top: '22%', left: '14%', width: '72%', height: 'auto'}}
                    src={logo}
                    alt=""
                    onLoad={() => setLogoLoaded(true)}
                />

                <div className={styles.bottomContainer}>
                    <div className={styles.textContainer}>
                        <h5 className={clsx(styles.heading, {})}>
                            Next Game
                        </h5>
                        <h3 className={clsx(styles.gameName, {})}>
                            {round ? round.bingoGame.name : 'Waiting'}
                        </h3>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button className={styles.joinButton} isSecondary
                                onClick={onClick}>{t('bingoBlock.joinButton')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BingoCard;
