import React from 'react';
import RegisterComponent from "../../components/Register/Register";

const Register = () => {
    return <>
        <RegisterComponent/>
    </>;
};

export default Register;
