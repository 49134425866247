import React from 'react';
import Menu from "../../components/Menu/Menu";

const Withdraw = () => {
    return <div>
        <Menu/>
        Withdraw
    </div>;
};

export default Withdraw;
