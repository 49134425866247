import React from 'react';
import styles from './Footer.module.scss';
import clsx from "clsx";
import {useTranslation} from "react-i18next";

function Footer({isShown}: { isShown: boolean }) {
    const {t} = useTranslation();

    return (
        <footer className={clsx(styles.wrapper, {
            [styles.hidden]: !isShown,
        })}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.groupOne}>
                        <h5 className={styles.header}>{t('footer.groupOne.visit')}</h5>
                        <p className={styles.aboutBody}>{t('footer.groupOne.firstAddress')} </p>
                        <p className={styles.aboutBody}>{t('footer.groupOne.secondAddress')}</p>
                        <p className={styles.link}>{t('footer.groupOne.link')}</p>
                    </div>
                    <div className={styles.groupTwo}>
                        <p className={styles.aboutBody}>{t('footer.groupTwo.resort')} {t('footer.groupTwo.operatedBy')}</p>
                    </div>
                    <div className={styles.groupThree}>
                        <p className={styles.aboutBody}>{t('footer.groupThree.firstPart')} {t('footer.groupThree.responsibleGaming')} {t('footer.groupThree.thirdPart')}</p>
                    </div>
                    <div className={styles.groupTwo}>
                        <p className={styles.aboutBody}>{t('footer.groupThree.gamblingProblem')} {t('footer.groupThree.help')} {t('footer.groupThree.hours')} {t('footer.groupThree.phone')}</p>
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>
                <p>{t('footer.copyright')}</p>
                <div className={styles.footerLinks}>
                    <a href="#">{t('footer.links.support')}</a>
                    <a href="#">{t('footer.links.privacy')}</a>
                    <a href="#">{t('footer.links.terms')}</a>
                    <a href="#">{t('footer.links.accessibility')}</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
