import React from 'react';
import './App.scss';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import Deposit from "./pages/Deposit/Deposit";
import Withdraw from "./pages/Withdraw/Withdraw";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import GameHistory from "./pages/GameHistory/GameHistory";
import Profile from "./pages/Profile/Profile";
import GameClient from "./pages/GameClient/GameClient";
import PageLayout from "./components/Layout/PageLayout";

function App() {


    return (
            <div className="App">
                <Routes>
                    <Route path="/" element={<PageLayout isLoginHidden={false}><Home /></PageLayout>} />
                    <Route path="/deposit" element={<PageLayout isLoginHidden={false}><Deposit /></PageLayout>} />
                    <Route path="/withdraw" element={<PageLayout isLoginHidden={false}><Withdraw /></PageLayout>} />
                    <Route path="/login" element={<PageLayout isLoginHidden={true}><Login /></PageLayout>} />
                    <Route path="/forgot-password" element={<PageLayout isLoginHidden={true}><Register /></PageLayout>} />
                    <Route path="/register" element={<PageLayout isLoginHidden={false}><Register /></PageLayout>} />
                    <Route path="/game-history" element={<PageLayout isLoginHidden={true}><GameHistory /></PageLayout>} />
                    <Route path="/profile" element={<PageLayout isLoginHidden={true}><Profile /></PageLayout>} />
                    <Route path="/game-client" element={<GameClient />} />
                </Routes>
            </div>
    );
}

export default App;
