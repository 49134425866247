import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Input from "../../elements/Input/Input";
import Util from "../../utils/Util";
import Button from "../../elements/Button/Button";
import {AppContext} from "../../context/AppContext";
import {useNavigate} from "react-router-dom";
import styles from './Profile.module.scss';
import apiClient from "../../utils/apiClient";
import axios from "axios";
import {useMutation} from "react-query";

interface IProfile {
    email: string;
    confirmEmail: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    nickname: string;
    fullName: string;
}

const ProfileComponent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {refetchCustomerBalance, userData} = useContext(AppContext);

    const [generalError, setGeneralError] = useState('');
    const [info, setInfo] = useState<IProfile>({
        email: '',
        confirmEmail: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        nickname: '',
        fullName: '',
    });
    const [inputErrors, setInputErrors] = useState({
        email: '',
        confirmEmail: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        nickname: '',
        fullName: '',
    });

    const updateUser = useMutation((data: any) => {
        const customerData = {
            ...data,
            streetAddress: data.address,
            firstname: data.fullName.split(' ')[0],
            lastname: data.fullName.split(' ')[1],
            phoneNumber: userData?.phoneNumber || Math.floor(100000000+Math.random()*899999999).toString(),
        }
        return apiClient.put('api/Customer', customerData);
    });

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        setInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        handleValidation([name]);
    };

    const handleValidation = (inputFields: any) => {
        const fields = info;
        const errors: IProfile = {...inputErrors};
        let isValid = true;

        inputFields.forEach((field: string) => {
            if (field === 'email') {
                if (!Util.emailRegex(fields.email)) {
                    errors.email = t('profile.emailValidation');
                    isValid = false;
                } else {
                    errors.email = '';
                }
            }
            if (field === 'fullName') {
                if (fields.fullName.length === 0) {
                    errors.fullName = t('register.fullNameValidation');
                    isValid = false;
                } else {
                    errors.fullName = '';
                }
            }
            if (field === 'address') {
                if (fields.address.length === 0) {
                    errors.address = t('profile.addressValidation');
                    isValid = false;
                } else {
                    errors.address = '';
                }
            }
            if (field === 'city') {
                if (fields.city.length === 0) {
                    errors.city = t('profile.cityValidation');
                    isValid = false;
                } else {
                    errors.city = '';
                }
            }
            if (field === 'state') {
                if (fields.state.length === 0) {
                    errors.state = t('profile.stateValidation');
                    isValid = false;
                } else {
                    errors.state = '';
                }
            }
            if (field === 'zip') {
                if (fields.zip.length === 0) {
                    errors.zip = t('profile.zipValidation');
                    isValid = false;
                } else {
                    errors.zip = '';
                }
            }
            if (field === 'nickname') {
                if (!Util.nicknameRegex(fields.nickname)) {
                    errors.nickname = t('profile.nicknameValidation');
                    isValid = false;
                } else {
                    errors.nickname = '';
                }
            }
        });

        setInputErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (handleValidation(['email', 'fullName', 'address', 'city', 'state', 'zip', 'nickname'])) {
            try {
                console.log('info', info);
                await updateUser.mutateAsync({
                    ...info,
                });
                navigate('/');
                refetchCustomerBalance();
            } catch (error: any) {
                console.error(error);

                if (error && error.status === 400) {
                    switch (error.response.data) {
                        default:
                            setGeneralError(t('profile.generalError'));
                            break;
                    }
                    setInfo((prevState) => ({
                        ...prevState,
                        password: '',
                    }));
                }
            }
        }
    };

    useEffect(() => {
        if (userData) {
            setInfo({
                email: userData.email,
                confirmEmail: userData.email,
                address: userData.streetAddress,
                city: userData.city || '',
                state: userData.state || '',
                zip: userData.zip || '',
                nickname: userData.nickname,
                fullName: userData.firstname + ' ' + userData.lastname,
            });
        }
    }, [userData]);

    return (
        <div className={styles.formContainer}>
            <h1 className={styles.heading}>{t('profile.heading')}</h1>
            <p className={styles.subheading}>{t('profile.subheading')}</p>
            <form className={styles.form} key={'profile'}>
                <Input
                    classNameInputWrapper={styles.nicknameinput}
                    type="text"
                    name={t('profile.nickname')}
                    initialValue={info.nickname}
                    value={info.nickname}
                    placeholder={t('profile.nickname')}
                    label={t('profile.nicknameLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleValidation([e.target.name])}
                    inputMode="text"
                    error={inputErrors.nickname}
                    success={inputErrors.nickname === '' && info.nickname !== ''}
                    comment={t('profile.nicknameComment')}
                />
                <Input
                    type="email"
                    name={t('profile.email')}
                    initialValue={info.email}
                    value={info.email}
                    placeholder={t('profile.email')}
                    label={t('profile.emailLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleValidation([e.target.name])}
                    inputMode="email"
                    error={inputErrors.email}
                    success={inputErrors.email === '' && info.email !== ''}
                />
                <Input
                    type="text"
                    name={t('profile.fullName')}
                    initialValue={info.fullName}
                    value={info.fullName}
                    placeholder={t('profile.fullName')}
                    label={t('profile.fullNameLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleValidation([e.target.name])}
                    inputMode="text"
                    error={inputErrors.fullName}
                    success={inputErrors.fullName === '' && info.fullName !== ''}
                />
                <Input
                    type="text"
                    name={t('profile.address')}
                    initialValue={info.address}
                    value={info.address}
                    placeholder={t('profile.address')}
                    label={t('profile.addressLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleValidation([e.target.name])}
                    inputMode="text"
                    error={inputErrors.address}
                    success={inputErrors.address === '' && info.address !== ''}
                />
                <div className={styles.zipCityContainer}>
                    <Input
                        classNameInputWrapper={styles.zipinput}
                        type="text"
                        name={t('profile.zip')}
                        initialValue={info.zip}
                        value={info.zip}
                        placeholder={t('profile.zip')}
                        label={t('profile.zipLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleValidation([e.target.name])}
                        inputMode="text"
                        error={inputErrors.zip}
                        success={inputErrors.zip === '' && info.zip !== ''}
                    />
                    <Input
                        classNameInputWrapper={styles.cityinput}
                        type="text"
                        name={t('profile.city')}
                        initialValue={info.city}
                        value={info.city}
                        placeholder={t('profile.city')}
                        label={t('profile.cityLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleValidation([e.target.name])}
                        inputMode="text"
                        error={inputErrors.city}
                        success={inputErrors.city === '' && info.city !== ''}
                    />
                </div>
                <Input
                    type="text"
                    name={t('profile.state')}
                    initialValue={info.state}
                    value={info.state}
                    placeholder={t('profile.state')}
                    label={t('profile.stateLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleValidation([e.target.name])}
                    inputMode="text"
                    error={inputErrors.state}
                    success={inputErrors.state === '' && info.state !== ''}
                />
                {generalError !== '' && <p className={styles.generalError}>{generalError}</p>}
                <Button
                    className={styles.submitButton}
                    isDisabled={inputErrors.email !== ''}
                    onClick={handleSubmit}
                >
                    {t('profile.updateButton')}
                </Button>
            </form>
        </div>
    );
};

export default ProfileComponent;
