import React, {useContext, useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Input from "../../elements/Input/Input";
import Util from "../../utils/Util";
import Button from "../../elements/Button/Button";
import {AppContext} from "../../context/AppContext";
import {useNavigate} from "react-router-dom";
import styles from './Register.module.scss';
import AvatarSelector from "../../elements/AvatarSelector/AvatarSelector";
import apiClient from "../../utils/apiClient";
import axios from "axios";
import {useMutation} from "react-query";

interface IRegister {
    email: string;
    confirmEmail: string;
    fullName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    password: string;
    nickname: string;
    externalId: string;
    gender: string;
    source: string;
    signupOffer: string;
    socialSecurityNumber: string;
    channelOptions: any;
    avatarId: number;
}

const RegisterComponent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {refetchCustomerBalance} = useContext(AppContext);
    const environment = process.env.REACT_APP_ENV;

    const [consentError, setConsentError] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [userConsent, setUserConsent] = useState(false);
    const [selected, setSelected] = useState(0);
    const [lastUpdatedFields, setLastUpdatedFields] = useState<string[]>([]);
    const [info, setInfo] = useState<IRegister>({
        email: '',
        confirmEmail: '',
        fullName: 'Testuser Testname',
        address: 'Teststreet 1',
        city: 'Testcity',
        state: 'TN',
        zip: '37018',
        password: '',
        nickname: '',
        externalId: '',
        gender: 'unspecified',
        source: '',
        signupOffer: '',
        socialSecurityNumber: `${Math.floor(Math.random()*7000000000)+190000000000}`, //environment === 'dev' ? '19801213-7710' : '',
        channelOptions: [],
        avatarId: 0,
    });
    const [inputErrors, setInputErrors] = useState({
        email: '',
        confirmEmail: '',
        fullName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        password: '',
        nickname: '',
        externalId: '',
        gender: 'unspecified',
        source: '',
        signupOffer: '',
        socialSecurityNumber: '',
        channelOptions: '',
        avatarId: 0,
    });

    useEffect(() => {
        handleValidation(lastUpdatedFields);
    }, [info, lastUpdatedFields]);

    const registerUser = useMutation((data: any) => {
        if (selected) {
            data.avatarId = selected;
        } else if (selected === 0) {
            data.avatarId = -1;
        } else {
            data.avatarId = -1;
        }

        const url = `${apiClient.baseURL}api/Customer`;
        return axios.post(url, {...data});
    });

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        if (name) {
            setLastUpdatedFields([name]);
        }
        setInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
//        handleValidation([name]);
    };

    const handleValidation = (inputFields: any) => {
        const fields = info;
        const errors: IRegister = {...inputErrors};
        let isValid = true;

        inputFields.forEach((field: string) => {
            if (field === 'email') {
                if (!Util.emailRegex(fields.email)) {
                    errors.email = t('register.emailValidation');
                    isValid = false;
                } else {
                    errors.email = '';
                }
            }
            if (field === 'password') {
                if (!Util.passwordRegex(fields.password)) {
                    errors.password = t('register.passwordValidation');
                    isValid = false;
                } else {
                    errors.password = '';
                }
            }
            if (field === 'confirmEmail') {
                if (fields.email !== fields.confirmEmail) {
                    errors.confirmEmail = t('register.confirmEmailValidation');
                    isValid = false;
                } else {
                    errors.confirmEmail = '';
                }
            }
            if (field === 'fullName') {
                if (fields.fullName.length === 0) {
                    errors.fullName = t('register.fullNameValidation');
                    isValid = false;
                } else {
                    errors.fullName = '';
                }
            }
            if (field === 'address') {
                if (fields.address.length === 0) {
                    errors.address = t('register.addressValidation');
                    isValid = false;
                } else {
                    errors.address = '';
                }
            }
            if (field === 'city') {
                if (fields.city.length === 0) {
                    errors.city = t('register.cityValidation');
                    isValid = false;
                } else {
                    errors.city = '';
                }
            }
            if (field === 'state') {
                if (fields.state.length === 0) {
                    errors.state = t('register.stateValidation');
                    isValid = false;
                } else {
                    errors.state = '';
                }
            }
            if (field === 'zip') {
                if (fields.zip.length === 0) {
                    errors.zip = t('register.zipValidation');
                    isValid = false;
                } else {
                    errors.zip = '';
                }
            }
            if (field === 'nickname') {
                if (!Util.nicknameRegex(fields.nickname)) {
                    errors.nickname = t('register.nicknameValidation');
                    isValid = false;
                } else {
                    errors.nickname = '';
                }
            }
        });

        setInputErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (handleValidation(['email', 'password', 'confirmEmail', 'fullName', 'address', 'city', 'state', 'zip', 'nickname'])) {
            try {
                if (!userConsent) {
                    setConsentError(t('register.consentError'));
                    return;
                } else {
                    setConsentError('');
                }
                console.log('info', info);
                await registerUser.mutateAsync({
                    ...info,
                    streetAddress: info.address,
                    username: info.email,
                    lastname: info.fullName.split(' ')[1],
                    firstname: info.fullName.split(' ')[0],

                });
                navigate('/login');
                // refetchCustomerBalance();
            } catch (error: any) {
                console.error(error);

                if (error && error.status === 400) {
                    switch (error.response.data) {
                        case 'Customer_With_Same_Email_Exists':
                            setGeneralError(t('register.emailExists'));
                            break;
                        case 'Customer_With_Same_Nickname_Exists':
                            setGeneralError(t('register.nicknameExists'));
                            break;
                        case 'SSN_Invalid_Format':
                            setGeneralError(t('register.ssnInvalidFormat'));
                            break;
                        default:
                            setGeneralError(t('register.generalError'));
                            break;
                    }
                    setInfo((prevState) => ({
                        ...prevState,
                        password: '',
                    }));
                }
            }
        }
    };

    return (
        <div className={styles.formContainer}>
            <h1 className={styles.heading}>{t('register.heading')}</h1>
            <p className={styles.subheading}>{t('register.subheading')}</p>
            <form className={styles.form} key={'signIn'}>
                <Input
                    type="email"
                    name={t('register.email')}
                    initialValue={''}
                    value={info.email}
                    placeholder={t('register.email')}
                    label={t('register.emailLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="email"
                    error={inputErrors.email}
                    success={inputErrors.email === '' && info.email !== ''}
                />
                <Input
                    type="email"
                    name={t('register.confirmEmail')}
                    initialValue={''}
                    value={info.confirmEmail}
                    placeholder={t('register.email')}
                    label={t('register.confirmEmailLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="email"
                    error={inputErrors.confirmEmail}
                    success={inputErrors.confirmEmail === '' && info.confirmEmail !== ''}
                />
                {/*
                <Input
                    type="text"
                    name={t('register.fullName')}
                    initialValue={''}
                    value={info.fullName}
                    placeholder={t('register.fullName')}
                    label={t('register.fullNameLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="text"
                    error={inputErrors.fullName}
                    success={inputErrors.fullName === '' && info.fullName !== ''}
                />
                <Input
                    type="text"
                    name={t('register.address')}
                    initialValue={''}
                    value={info.address}
                    placeholder={t('register.address')}
                    label={t('register.addressLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="text"
                    error={inputErrors.address}
                    success={inputErrors.address === '' && info.address !== ''}
                />
                <div className={styles.zipCityContainer}>
                    <Input
                        classNameInputWrapper={styles.zipinput}
                        type="text"
                        name={t('register.zip')}
                        initialValue={''}
                        value={info.zip}
                        placeholder={t('register.zip')}
                        label={t('register.zipLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                            if (
                                !inputErrors.email &&
                                !inputErrors.password
                            ) {
                                handleValidation([e.target.name]);
                            }
                        }}
                        inputMode="text"
                        error={inputErrors.zip}
                        success={inputErrors.zip === '' && info.zip !== ''}
                    />
                    <Input
                        classNameInputWrapper={styles.cityinput}
                        type="text"
                        name={t('register.city')}
                        initialValue={''}
                        value={info.city}
                        placeholder={t('register.city')}
                        label={t('register.cityLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                            if (
                                !inputErrors.email &&
                                !inputErrors.password
                            ) {
                                handleValidation([e.target.name]);
                            }
                        }}
                        inputMode="text"
                        error={inputErrors.city}
                        success={inputErrors.city === '' && info.city !== ''}
                    />
                </div>
                <Input
                    type="text"
                    name={t('register.state')}
                    initialValue={''}
                    value={info.state}
                    placeholder={t('register.state')}
                    label={t('register.stateLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="text"
                    error={inputErrors.state}
                    success={inputErrors.state === '' && info.state !== ''}
                />
                */}
                <Input
                    type="password"
                    name={t('login.password')}
                    initialValue={''}
                    value={info.password}
                    placeholder={t('login.password')}
                    label={t('login.passwordLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name]);
                        }
                    }}
                    inputMode="text"
                    error={inputErrors.password}
                    success={inputErrors.password === '' && info.password !== ''}
                    comment={t('register.passwordComment')}
                />
                <div className={styles.nicknameContainer}>
                    <Input
                        classNameInputWrapper={styles.nicknameinput}
                        type="text"
                        name={t('register.nickname')}
                        initialValue={''}
                        value={info.nickname}
                        placeholder={t('register.nickname')}
                        label={t('register.nicknameLabel')}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                            if (
                                !inputErrors.email &&
                                !inputErrors.password
                            ) {
                                handleValidation([e.target.name]);
                            }
                        }}
                        inputMode="text"
                        error={inputErrors.nickname}
                        success={inputErrors.nickname === '' && info.nickname !== ''}
                        comment={t('register.nicknameComment')}
                    />
                </div>
                <AvatarSelector heading={t('register.avatarHeading')}
                                currentAvatar={info.avatarId}
                                selected={selected}
                                setSelected={setSelected}
                                key={'avatarSelector'}/>
                <div className={styles.consentContainer}>
                    <label className={styles.consent} htmlFor="consent">
                        <input
                            name="consent"
                            id="consent"
                            type="checkbox"
                            onChange={(e) => setUserConsent(e.target.checked)}
                        />
                        {t('register.approveTerms')}
                    </label>
                    {consentError !== '' && <p className={styles.generalError}>{consentError}</p>}
                    {generalError !== '' && <p className={styles.generalError}>{generalError}</p>}
                </div>
                <Button
                    className={styles.submitButton}
                    isDisabled={inputErrors.email !== '' || inputErrors.password !== ''}
                    onClick={handleSubmit}
                >
                    {t('register.registerButton')}
                </Button>
            </form>
        </div>
    );
};

export default RegisterComponent;
