import React, {useState} from "react";
import clsx from "clsx";
import {userImages} from "../../utils/getAvatar";
import styles from './AvatarSelector.module.scss';

interface AvatarSelectorProps {
    heading: string;
    currentAvatar: number;
    selected: number;
    setSelected: (index: number) => void;
}

const AvatarSelector = ({heading, currentAvatar, selected, setSelected}: AvatarSelectorProps) => {

    const handleOnClickAvatar = (index: number) => {
        setSelected(index);
    };

    return (
            <div className={styles.selectContainer}>
                <label className={styles.label}>
                    {heading}
                </label>
                <div className={styles.avatarsWrapper}>
                    {userImages &&
                        userImages.map((userImage, index) => {
                            return (
                                <button
                                    key={`${userImage}${index}`}
                                    type="button"
                                    className={clsx(styles.avatar, {
                                        [styles.selected]: selected === index,
                                    })}
                                    onClick={() => handleOnClickAvatar(index)}
                                >
                                    <img src={userImage} alt="Avatar" />
                                </button>
                            );
                        })}
                </div>
            </div>
    );
}

export default AvatarSelector;
