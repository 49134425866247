import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styles from './SignIn.module.scss';
import Input from "../../elements/Input/Input";
import Util from "../../utils/Util";
import Button from "../../elements/Button/Button";
import {AppContext} from "../../context/AppContext";
import {useNavigate} from "react-router-dom";

const SignIn = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {login, refetchCustomerBalance} = useContext(AppContext);
    const [info, setInfo] = useState({
        email: '',
        password: '',
    });
    const [inputErrors, setInputErrors] = useState({
        email: '',
        password: '',
    });
    const [generalError, setGeneralError] = useState('');
    const showDemoLogin = JSON.parse(process.env.REACT_APP_SHOW_DEMO_LOGIN || 'false');
    const demoUser = JSON.parse(process.env.REACT_APP_DEMO_USER || '{}');
    const demoUsersExtra = [
        {username: demoUser.username, password: demoUser.password},
        {username: 'noreply1@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply2@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply3@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply4@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply5@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply6@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply7@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply8@randomstate.se', password: 'G2e2024!'},
        {username: 'noreply9@randomstate.se', password: 'G2e2024!'}
    ];


    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        setInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        handleValidation([name], [value]);
    };

    const handleValidation = (inputFields: any, values:any) => {
        // const fields = info;
        const errors: { email: string; password: string } = {...inputErrors};
        let isValid = true;

        let fieldIndex = 0;
        inputFields.forEach((field: string) => {
            let fieldValue = values[fieldIndex];
            if (field === 'email') {
                if (!Util.emailRegex(fieldValue)) {
                    errors.email = t('login.emailValidation');
                    isValid = false;
                } else {
                    errors.email = '';
                }
            }
            if (field === 'password') {
                if (fieldValue.length === 0) {
                    errors.password = t('login.passwordValidation');
                    isValid = false;
                } else {
                    errors.password = '';
                }
            }
            fieldIndex++;
        });

        setInputErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (handleValidation(['email', 'password'], [info.email, info.password])) {
            try {
                console.log('info', info);
                await login(info.email, info.password);
                navigate('/');
                refetchCustomerBalance();
            } catch (error: any) {
                console.error(error);

                if (error && error.status === 400) {
                    setGeneralError(t('login.error'));
                    setInputErrors({
                        email: '',
                        password: '',
                    });
                    setInfo((prevState) => ({
                        ...prevState,
                        password: '',
                    }));
                }
            }
        }
    };

    const handleDemoLogin1 = async () => {
        await handleDemoLogin(0);
    }
    const handleDemoLogin2 = async () => {
        await handleDemoLogin(1);
    }
    const handleDemoLogin3 = async () => {
        await handleDemoLogin(2);
    }
    const handleDemoLogin4 = async () => {
        await handleDemoLogin(3);
    }
    const handleDemoLogin5 = async () => {
        await handleDemoLogin(4);
    }
    const handleDemoLogin6 = async () => {
        await handleDemoLogin(5);
    }
    const handleDemoLogin7 = async () => {
        await handleDemoLogin(6);
    }
    const handleDemoLogin8 = async () => {
        await handleDemoLogin(7);
    }

    const handleDemoLogin = async (userIndex:number) => {
        try {
            let randomUser = demoUsersExtra[userIndex];
            await login(randomUser.username, randomUser.password);
            navigate('/');
            refetchCustomerBalance();
        } catch (error: any) {
            console.error(error);

            if (error && error.status === 400) {
                setInputErrors({
                    email: t('login.error'),
                    password: t('login.error'),
                });
            }
        }
    }

    return (
        <div className={styles.formContainer}>
            <h1 className={styles.heading}>{t('login.heading')}</h1>
            <form className={styles.form} key={'signIn'}>
                <Input
                    type="email"
                    name={t('login.email')}
                    initialValue={''}
                    value={info.email}
                    placeholder={t('login.email')}
                    label={t('login.emailLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name], [e.target.value]);
                        }
                    }}
                    inputMode="email"
                    error={inputErrors.email}
                    success={inputErrors.email === '' && info.email !== ''}
                />
                <Input
                    type="password"
                    name={t('login.password')}
                    initialValue={''}
                    value={info.password}
                    placeholder={t('login.password')}
                    label={t('login.passwordLabel')}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => {
                        if (
                            !inputErrors.email &&
                            !inputErrors.password
                        ) {
                            handleValidation([e.target.name], [e.target.value]);
                        }
                    }}
                    inputMode="text"
                    error={inputErrors.password}
                    success={inputErrors.password === '' && info.password !== ''}
                />
                {generalError && <p className={styles.generalError}>{generalError}</p>}
                <Button
                    className={styles.submitButton}
                    isDisabled={inputErrors.email !== '' || inputErrors.password !== ''}
                    onClick={handleSubmit}
                >
                    {t('login.loginButton')}
                </Button>
            </form>
            <a
                className={styles.forgotPassword}
                href="/forgot-password"
            >
                {t('login.forgotPassword')}
            </a>
            <div className={styles.loginOthers}>
                <span>Need an account? </span>
                <a
                    className={styles.forgotPassword}
                    href="/register"
                >
                    {t('login.registerButton')}
                </a>
            </div>
            {showDemoLogin && (
                <div className={styles.demoLogin}>
                    <p>Or</p>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin1}
                    >
                        {t('login.demoLoginButton')}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin2}
                    >
                        {`${t('login.demoLoginButton')} 2`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin3}
                    >
                        {`${t('login.demoLoginButton')} 3`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin4}
                    >
                        {`${t('login.demoLoginButton')} 4`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin5}
                    >
                        {`${t('login.demoLoginButton')} 5`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin6}
                    >
                        {`${t('login.demoLoginButton')} 6`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin7}
                    >
                        {`${t('login.demoLoginButton')} 7`}
                    </Button>
                    <Button
                        className={styles.submitButton}
                        onClick={handleDemoLogin8}
                    >
                        {`${t('login.demoLoginButton')} 8`}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SignIn;
