import React from 'react';
import clsx from "clsx";
import styles from './Radio.module.scss';

interface RadioButtonProps {
    name: string;
    label: string;
    isDisabled: boolean;
    isChecked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = ({ name, label, isDisabled, isChecked, onChange }: RadioButtonProps) => {
    return (
        <div
            className={clsx(styles.wrapper, {
                [styles.isChecked]: isChecked,
                [styles.isDisabled]: isDisabled,
            })}
        >
            <input
                type="radio"
                className={styles.input}
                disabled={isDisabled}
                id={name}
                name={name}
                checked={isChecked}
                onChange={onChange}
            />
            <label className={styles.label} htmlFor={name}>
                <img src={label} alt={name}/>
            </label>
        </div>
    );
}

export default RadioButton;
