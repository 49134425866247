import React, {useContext, useState, useEffect} from 'react';
import Hero from "../../components/Hero/Hero";
import BingoBlock from "../../components/BingoBlock/BingoBlock";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../context/AppContext";
import TextBlock from "../../elements/TextBlock/TextBlock";
import Spinner from "../../elements/Spinner/Spinner";

const Home = () => {
    const {t} = useTranslation();
    const {userData, loading} = useContext(AppContext)

    if (loading) {
        return <Spinner/>;
    }

    return (
        <>
            {!userData ?
                <Hero
                    title={t('hero.heading')}
                    text={t('hero.subheading')}
                    primaryButton={t('hero.registerButton')}
                    secondaryButton={t('hero.loginButton')}
                    textLinkButton={t('hero.subheadingLink')}
                /> :
                <TextBlock
                    className={'welcomeBlock'}
                    heading={t('welcome.heading', {name: userData.nickname})}
                    paragraphs={[t('welcome.subheading')]}
                    margin={'30px 0 30px 0'}
                />
            }

            <BingoBlock
                heading={t('bingoBlock.heading')}
            />
            <TextBlock
                heading={t('welcome.playBingo')}
                paragraphs={[t('welcome.paragraph1'), t('welcome.paragraph2'), t('welcome.paragraph2')]}
                margin={'30px 0 30px 0'}
            />
        </>
        )
};

export default Home;
