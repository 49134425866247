import React from 'react';
import SignIn from "../../components/SignIn/SignIn";

const Login = () => {
    return (
        <>
            <SignIn/>
        </>
    );
};

export default Login;
