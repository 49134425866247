import React from 'react';
import Button from "../../elements/Button/Button";
import styles from './Hero.module.scss'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

interface HeroProps {
    title: string;
    text: string;
    primaryButton: string;
    secondaryButton: string;
    textLinkButton?: string;
    primaryButtonLink?: string;
    secondaryButtonLink?: string;
    textLinkButtonLink?: string;
}

const Hero = ({
                  title,
                  text,
                  primaryButton,
                  secondaryButton,
                  textLinkButton,
                  primaryButtonLink,
                  secondaryButtonLink,
                  textLinkButtonLink
              }: HeroProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.heroContainer}>
            <div className={styles.content}>
                <h1 className={styles.heading}>{title}</h1>
                <h5 className={styles.subheading}>{text}</h5>
                <h5 className={clsx(styles.subheading, styles.link)}
                    onClick={() => {
                        navigate({
                            pathname: textLinkButtonLink ? textLinkButtonLink : '/terms',
                        });
                }}>
                    {textLinkButton ? textLinkButton : t('hero.subheadingLink')}
                </h5>
                <div className={styles.buttonContainer}>
                    <Button isPrimaryBig onClick={() => {
                        navigate({
                            pathname: primaryButtonLink ? primaryButtonLink : '/register',
                        });
                    }}>
                        {primaryButton}
                    </Button>
                    <Button isTertiary onClick={() => {
                        navigate({
                            pathname: secondaryButtonLink ? secondaryButtonLink : '/login',
                        });
                    }}>
                        {secondaryButton}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
