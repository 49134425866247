import React from 'react';
import Header from '../Header/Header';
import Footer from "../Footer/Footer";

interface PageLayoutProps {
    children: React.ReactNode;
    isLoginHidden: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, isLoginHidden = false }) => {
    return (
        <>
            <Header isLoginHidden={isLoginHidden} />
            <main>{children}</main>
            <Footer isShown={true}/>
        </>
    );
};

export default PageLayout;
