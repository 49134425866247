export const en = {
    translation: {
        header: {
            wallet: 'Wallet: ',
            signIn: 'Sign in',
            profileMenu: {
                profile: 'Profile',
                gameHistory: 'Game history',
                deposit: 'Deposit',
                withdraw: 'Withdraw',
                logout: 'Logout',
            }
        },
        hero: {
            heading: 'Register now and get a $100 bonus',
            subheading: 'Deposit $100, get $100 to play for.',
            subheadingLink: 'Terms and conditions apply »',
            registerButton: 'Register now',
            loginButton: 'Sign in',
        },
        welcome: {
            heading: 'Welcome, {{name}}!',
            subheading: 'Aenean blandit posuere lacus,  nec scelerisque magna facilisis facilisis. Aenean scelerisque eros at risus cursus dignissim. Maecenas pretium ante justo, sit amet hendrerit arcu varius et. Nulla pellentesque pretium sapien, in tristique felis ornare et. Sed dignissim orci lectus, nec cursus dolor imperdiet eu.',
            playBingo: 'Play Bingo online!',
            paragraph1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque  lorem augue, dapibus ac augue ut, hendrerit tempus risus. Phasellus in  gravida mi, in eleifend nisl. Praesent in augue pulvinar, egestas erat a, pretium nisi. Aenean eu ex auctor, porttitor leo eget, ullamcorper erat. Pellentesque accumsan sodales porta.',
            paragraph2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque  lorem augue, dapibus ac augue ut, hendrerit tempus risus. Phasellus in  gravida mi, in eleifend nisl. Praesent in augue pulvinar, egestas erat a, pretium nisi. Aenean eu ex auctor, porttitor leo eget, ullamcorper erat. Pellentesque accumsan sodales porta.',
        },
        login: {
            heading: 'Sign in',
            subheading: 'Welcome back! Sign in to continue playing.',
            email: 'email',
            emailLabel: 'E-mail Address*',
            password: 'password',
            passwordLabel: 'Password',
            loginButton: 'Sign in',
            emailValidation: 'Please enter a valid e-mail address',
            passwordValidation: 'Please enter a password',
            error: 'Incorrect e-mail or password! Try again.',
            forgotPassword: 'Forgot your password?',
            demoLoginButton: 'Demo login',
            registerButton: 'Register here',
        },
        register: {
            heading: 'Sign up to win',
            subheading: 'Bonus offer. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque  lorem augue, dapibus ac augue ut, hendrerit tempus risus. Phasellus in  gravida mi, in eleifend nisl. Praesent in augue pulvinar, egestas erat a, pretium nisi. Aenean eu ex auctor, porttitor leo eget, ullamcorper erat. Pellentesque accumsan sodales porta.',
            email: 'email',
            emailLabel: 'E-mail Address*',
            confirmEmail: 'confirmEmail',
            confirmEmailLabel: 'Confirm E-mail Address*',
            fullName: 'fullName',
            fullNameLabel: 'Full Name*',
            address: 'address',
            addressLabel: 'Address',
            password: 'password',
            passwordLabel: 'Password',
            passwordComment: 'At least 8 characters long using small and capital letters, a number and a special character.',
            zip: 'zip',
            zipLabel: 'Zip Code',
            city: 'city',
            cityLabel: 'City',
            state: 'state',
            stateLabel: 'State',
            nickname: 'nickname',
            nicknameLabel: 'Nickname in chat',
            nicknameComment: 'At least 4 characters long with letters and numbers only.',
            avatarHeading: 'avatar',
            approveTerms: 'I approve of the terms and conditions. Longer regulatory text so people won’t understand what they approve of. Lorem ipsum dolor sit amet.',
            registerButton: 'Register account',
            emailValidation: 'Please enter a valid e-mail address',
            confirmEmailValidation: 'E-mail addresses do not match',
            fullNameValidation: 'Please enter your full name',
            addressValidation: 'Please enter your address',
            zipValidation: 'Please enter your zip code',
            cityValidation: 'Please enter your city',
            stateValidation: 'Please enter your state',
            passwordValidation: 'Please enter a password',
            nicknameValidation: 'Please enter a nickname',
            error: 'Incorrect e-mail or password! Try again.',
            consentError: 'You must approve the terms and conditions to register.',
            generalError: 'Something went wrong, try again.',
            emailExists: 'E-mail already exists, try to sign in.',
            nicknameExists: 'Nickname already exists, try another one.',
            ssnInvalidFormat: 'Invalid SSN format',
        },
        profile: {
            heading: 'My profile',
            subheading: 'Aenean blandit posuere lacus,  nec scelerisque magna facilisis facilisis. Aenean scelerisque eros at risus cursus dignissim. Maecenas pretium ante justo, sit amet hendrerit arcu varius et. Nulla pellentesque pretium sapien, in tristique felis ornare et. Sed dignissim orci lectus, nec cursus dolor imperdiet: Change password',
            email: 'email',
            emailLabel: 'E-mail Address*',
            fullName: 'fullName',
            fullNameLabel: 'Full Name*',
            address: 'address',
            addressLabel: 'Address',
            zip: 'zip',
            zipLabel: 'Zip Code',
            city: 'city',
            cityLabel: 'City',
            state: 'state',
            stateLabel: 'State',
            nickname: 'nickname',
            nicknameLabel: 'Nickname in chat',
            nicknameComment: 'At least 4 characters long with letters and numbers only.',
            updateButton: 'Update',
            emailValidation: 'Please enter a valid e-mail address',
            fullNameValidation: 'Please enter your full name',
            addressValidation: 'Please enter your address',
            zipValidation: 'Please enter your zip code',
            cityValidation: 'Please enter your city',
            stateValidation: 'Please enter your state',
            nicknameValidation: 'Please enter a nickname',
            error: 'Incorrect data! Try again.',
            generalError: 'Something went wrong, try again.',
            nicknameExists: 'Nickname already exists, try another one.',
        },
        history: {
            heading: 'Game history',
            subheading: 'Aenean blandit posuere lacus,  nec scelerisque magna facilisis facilisis. Aenean scelerisque eros at risus cursus dignissim. Maecenas pretium ante justo, sit amet hendrerit arcu varius et. Nulla pellentesque pretium sapien, in tristique felis ornare et. Sed dignissim orci lectus, nec cursus dolor imperdiet eu.',
            date: 'Date played',
            game: 'Game Name',
            wager: 'Wager',
            prize: 'Prize',
            cost: 'Cost',
            winnings: 'Winnings',
            symbols: 'Symbols',
            page: 'Page {{page}}',
            previous: 'Previous',
            next: 'Next',
            replay: 'Replay round >>',
            noData: 'No game history available.',
        },
        deposit: {
            heading: 'Deposit',
            subheading: 'Subject to how the integration with the payment service will look. Aenean scelerisque eros at risus cursus dignissim. Maecenas pretium ante justo, sit amet hendrerit arcu varius et. Nulla pellentesque pretium sapien, in tristique felis ornare et. Sed dignissim orci lectus, nec cursus dolor imperdiet eu.',
            amount: 'amount',
            selectMethod: 'Select deposit method',
            amountLabel: 'Amount in dollars',
            depositButton: 'Deposit',
            comment: 'You will be directed to FreedomPays payment gateway. After the completed payment you will be returned here.',
            amountValidation: 'Please enter an amount',
            error: 'Something went wrong, try again.',
        },
        bingoBlock: {
            heading: 'Upcoming bingo games',
            joinButton: 'Join room',
        },
        footer: {
            copyright: '© Resort Whitelabel Bingo 2024',
            links: {
                support: 'Customer Support',
                terms: 'Terms of Use',
                privacy: 'Privacy Policy',
                accessibility: 'Accessibility',
            },
            groupOne: {
                visit: 'Visit our Casino Resort at',
                firstAddress: '123 Road House Road',
                secondAddress: 'Nearby City, SQ 12345',
                link: 'www.casinoresortsite.com',
            },
            groupTwo: {
                resort: 'Resort Whitelabel Bingo is owned and operated by',
                operatedBy: 'The Confederated Tribes of the XXXXX Reservation.',
            },
            groupThree: {
                firstPart: 'Resort Whitelabel Bingo is committed to making',
                responsibleGaming: 'Responsible Gaming',
                thirdPart: 'an integral part of our daily operations.',
                gamblingProblem: 'If you or someone you know has a gambling problem,',
                help: 'help is available',
                hours: '24 hours a day.',
                phone: 'Call 1-800-547-6133.',
            },
        },
        menu: {
            play: 'PLAY',
            tryDemo: 'TRY A DEMO ROUND',
            topScores: 'TOP SCORES',
            prizes: 'PRIZES',
            howItWorks: 'HOW IT WORKS',
            achievements: 'ACHIEVEMENTS',
            statistics: 'STATISTICS',
        },
        start: {
            heading: 'Welcome',
            subHeading: 'Time to test your sport quiz skills - and your luck to win cash!',
            playButton: 'PLAY',
            gameName: 'SportsMaster',
            demoButton: 'TRY A DEMO ROUND',
            seeTopScoreButton: 'See top scores',
            seePrizesButton: 'See prizes',
        },
        amount: '{{amount}}',
        currencyFormat: 'EUR',
        currencyLocale: 'en-GB',
        doNotSupportLandscape: 'Rotate device to play',
        errors: {
            default: 'Something went wrong!',
            userBalance: 'Could not fetch balance, reload the page to try again.',
            purchase: 'A problem occured during purchase, try again.',
            demoPurchase: 'Something went wrong with your demo ticket, try again.',
            gameStop: 'You have game stop activated.',
            limitNotSet:
                'You have to set your game limits before you purchasing tickets.',
            noAvailableTickets: 'There are no available tickets to purchase.',
            getTicketError: 'Something went wrong when collecting your ticket.',
            somethingWrongWithTicket: 'Unknown error with your ticket.',
            replayLoginError: 'You must login to replay your ticket.',
        },
    },
};
