import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default class Util {
  public static getParameter(name: string): string {
    const params: URLSearchParams = new URLSearchParams(window.location.href);
    return params.get(name) as string;
  }

  public static stringify(value: any): string {
    return JSON.stringify(value, null, 4);
  }

  public static isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public static isDesktop(): boolean {
    return !Util.isMobile();
  }

  public static formatCurrency(amount: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
        style: 'currency',
        currency: 'USD'
    }).format(amount);
  }

  public static emailRegex(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email);
  }

  public static passwordRegex(password: string): boolean {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
  }

  public static nicknameRegex(nickname: string): boolean {
    return /^[A-Za-z\d]{4,}$/.test(nickname);
  }

  public static formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(value);
  }

  public static getTimeUntil(date: string): string {
    const time = dayjs.duration(dayjs(date).diff(dayjs()));
    if (time.asSeconds() <= 0) {
      return "Start!";
    }
    const minutes = String(Math.floor(time.asMinutes())).padStart(2, '0');
    const seconds = String(time.seconds()).padStart(2, '0');
    return `${minutes}:${seconds}`;
  }

  public static getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static customRound(number: number) {
    const decimalPart = number % 1;
    if (decimalPart <= 0.5) {
      return Math.floor(number);
      // eslint-disable-next-line no-else-return
    } else {
      return Math.ceil(number);
    }
  }
}
